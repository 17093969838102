import { createSlice } from '@reduxjs/toolkit';

const notifications = createSlice({
  name: 'notification',
  initialState: [],
  reducers: {
    setNotification(state, {payload}){
      const newState = state.filter((item) => item.type !== payload.type);
      return [...newState, { type: payload.type, message: payload.message }];
    },
    unsetNotification(state,{payload}){
      return state.filter((item) => item.message !== payload.message);
    }
  }
})

export const {setNotification, unsetNotification} = notifications.actions
export default notifications.reducer
