import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import '../../../../../utils/i18next';
import useMobile from '../../../../../hooks/useMobile';
import {
  decrement,
  increment,
  removeFromCart,
} from '../../../../../redux/shop/shopSlice';
import { ServiceItemMobile } from './ServiceItemMobile';
import { ServiceItemDesctop } from './ServiceItemDesctop';

export default function ServicesItemShop({ dataItem }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isMobile = useMobile();

  const [totalCost, setTotalCost] = useState(0);
  const cost = Number(dataItem.price);

  useEffect(() => {
    let total = cost * dataItem.qty;
    setTotalCost(total);
  }, [cost, dataItem.qty, totalCost]);

  return isMobile ? (
    <ServiceItemMobile
      title={t(dataItem.title)}
      descriptions={t(dataItem.descriptions)}
      detailsPrice={t(dataItem.detailsPrice)}
      alt={dataItem.alt}
      totalCost={totalCost}
      qty={dataItem.qty}
      icon={dataItem.icon}
      iconGoogle={dataItem.iconGoogle}
      onIncrement={() => dispatch(increment(dataItem._id))}
      onDecrement={() => dispatch(decrement(dataItem._id))}
      onRemoveFromCart={() => dispatch(removeFromCart(dataItem._id))}
    />
  ) : (
    <ServiceItemDesctop
      alt={dataItem.alt}
      title={t(dataItem.title)}
      descriptions={t(dataItem.descriptions)}
      detailsPrice={t(dataItem.detailsPrice)}
      qty={dataItem.qty}
      totalCost={totalCost}
      icon={dataItem.icon}
      iconGoogle={dataItem.iconGoogle}
      onIncrement={() => dispatch(increment(dataItem._id))}
      onDecrement={() => dispatch(decrement(dataItem._id))}
      onRemoveFromCart={() => dispatch(removeFromCart(dataItem._id))}
    />
  );
}
