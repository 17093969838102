import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useValiadField from '../../../../hooks/useValidateField';
import { modalConnectSelector } from '../../../../redux/modal/modalSelectors';
import { getAddressSelector, getNameSelector, getPhoneSelector } from '../../../../redux/user/userSelectors';
import { makeAlertNotification } from '../../../../redux/notifications/notificationOperations';
import { setAddressAction, setNameAction, setPhoneAction } from '../../../../redux/user/userSlice';
import { useTranslation } from 'react-i18next';
import useMobile from '../../../../hooks/useMobile';
import useTitle from '../../../../hooks/useTitle';
import '../../../../utils/i18next';
import { sendTariffs } from '../../../../redux/tarrifs/tarrifsOperations';
import { loadingSelector } from '../../../../redux/loader/loaderSelector';
import { MobileConnectElement } from "./MobileConnectElement";
import { DesctopConnectElement } from "./DesctopConnectElement";

export default function ConnectElement() {
  const { t } = useTranslation();
  const recaptchaRef = useRef();
  const isMobile = useMobile();
  const dispatch = useDispatch();
  useTitle(t('nav.connect'));
  const [token, setToken] = useState('');

  const currentTariff = useSelector(modalConnectSelector);
  const nameRedux = useSelector(getNameSelector);
  const phoneRedux = useSelector(getPhoneSelector);
  const tariffRedux = useSelector(getAddressSelector);
  const load = useSelector(loadingSelector);

  const [validName, setValidName] = useValiadField();
  const [validPhone, setValidPhone] = useValiadField();
  const [validAddress, setValidAddress] = useValiadField();

  useEffect(() => {
    setValidName(nameRedux);
    setValidPhone(phoneRedux);
    setValidAddress(tariffRedux);
  }, [nameRedux, phoneRedux, tariffRedux]);

  const handlerOnSubmit = async (e) => {
    e.preventDefault();
    if (!token) {
      dispatch(makeAlertNotification(t('validation.tokenAlert')));
      return;
    }
    if (!validName) {
      dispatch(makeAlertNotification(t('validation.alertIdName')));
      return;
    }
    if (!validPhone) {
      dispatch(makeAlertNotification(t('validation.alertIdPhone')));
    }
    if (!validAddress) {
      dispatch(makeAlertNotification(t('validation.alertIdAddress')));
    }
    const credentials = {
      name: nameRedux,
      phone: phoneRedux,
      address: tariffRedux,
    };
    const tariff = {
      name: currentTariff.name,
      cost: currentTariff.cost,
      speed: currentTariff.speed,
    };

    if (token) {
      dispatch(sendTariffs(tariff, credentials, token));
      return;
    }
  };

  const handleOnChangeName = ({ target: { value } }) => {
    dispatch(setNameAction(value));
    setValidName(value);
  };
  const handleOnChangePhone = ({ target: { value } }) => {
    dispatch(setPhoneAction(value));
    setValidPhone(value);
  };
  const handleOnChangeAddress = ({ target: { value } }) => {
    dispatch(setAddressAction(value));
    setValidAddress(value);
  };

  return isMobile ? (
    <MobileConnectElement
      load={load}
      handleOnChangeName={handleOnChangeName}
      handlerOnSubmit={handlerOnSubmit}
      handleOnChangePhone={handleOnChangePhone}
      handleOnChangeAddress={handleOnChangeAddress}
      onExpired={() => setToken('')}
      onSetToken={(token) => setToken(token)}

      nameRedux={nameRedux}
      phoneRedux={phoneRedux}
      tariffRedux={tariffRedux}
      validName={validName}
      validPhone={validPhone}
      currentTariff={currentTariff}
      validAddress={validAddress}
      ref={recaptchaRef}

      tariffTitleCost={t('tariffs_title.cost')}
      tariffTitleSpeed={t('tariffs_title.speed')}
      tariffTitlePackage={t('tariffs_title.package')}
      valid={t('validation.valid')}
      inValid={t('validation.invalidField')}
      formYourName={t('form.yourName')}
      formYourPhonec={t('form.yourAddress')}
      formYourAddress={t('form.yourPhone')}
      send={t('ui.send')}
      mobile={isMobile}
      hl={t('recapchaHL')}/>
  ) : (
    <DesctopConnectElement
      handleOnChangeName={handleOnChangeName}
      handleOnChangePhone={handleOnChangePhone}
      onSubmit={handlerOnSubmit}
      handleOnChangeAddress={handleOnChangeAddress}
      onSetToken={(token) => setToken(token)}
      onExpired={() => setToken('')}

      currentTariff={currentTariff}
      tariffRedux={tariffRedux}
      phoneRedux={phoneRedux}
      nameRedux={nameRedux}
      validPhone={validPhone}
      validName={validName}
      validAddress={validAddress}
      ref={recaptchaRef}
      mobile={isMobile}
      load={load}

      tariffTitleCost={t('tariffs_title.cost')}
      tariffTitleSpeed={t('tariffs_title.speed')}
      tariffTitlePackage={t('tariffs_title.package')}
      valid={t('validation.valid')}
      inValid={t('validation.invalidField')}
      formYourName={t('form.yourName')}
      formYourPhone={t('form.yourPhone')}
      formYourAddress={t('form.yourAddress')}
      send={t('ui.send')}
      hl={t('recapchaHL')}
    />
  );
}
