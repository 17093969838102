import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import {
  persistReducer,
  persistStore,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { combineReducers } from 'redux';

import modal from './modal/modalSlice';
import cart from './shop/shopSlice';
import notifications from './notifications/notificationSlice';
import activeflipId from './fliped/flipedSlice';
import responseTariff from './tarrifs/tariffsSlice';
import responseServices from "./services/servicesSlice"
import user from './user/userSlice';
import loading from "./loader/loaderSlice"

const persistConfigCart = {
  key: 'cart',
  storage,
};
const persisConfigUser = {
  key: 'user',
  storage,
};
const ui = combineReducers({ modal, notifications, activeflipId, loading});

const persistShop = persistReducer(persistConfigCart, cart);
const persistUser = persistReducer(persisConfigUser, user);

export const store = configureStore({
  reducer: {
    ui,
    persistShop,
    persistUser,
    responseTariff,
    responseServices,
  },
  middleware: getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  }),
});
export const persistor = persistStore(store);
