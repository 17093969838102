import {pathOr} from 'ramda';

import {orderTariffRequest, orderTariffSuccess, orderTariffError} from './tariffsSlice';
import {setModalConnect} from '../modal/modalSlice';
import {sendTariffWithCredentials} from '../../utils/fullinetAPI';
import {
    makeSuccessSendedNotification,
    makeAlertNotification,
    makeSuccessUsersInfoNotification,
} from '../notifications/notificationOperations';
import { setLoading } from "../loader/loaderSlice";

export const sendTariffs = (tariff, credentials, token) => (dispatch) => {
    dispatch(orderTariffRequest);
    dispatch(setLoading(true))
    sendTariffWithCredentials(tariff, credentials, token)
        .then((response) => {
            dispatch(orderTariffSuccess(response.status));
            dispatch(
                makeSuccessSendedNotification(
                    'Успішно відправлено, очікуйте на телефонний дзвінок'
                )
            );
            setTimeout(() => {
                dispatch(
                    makeSuccessUsersInfoNotification(
                        'Ваше замовлення вже в потрібних руках. Натисніть щоб закрити.'
                    )
                );
            }, 2000);
            setTimeout(() => {
                dispatch(setModalConnect(false));
            }, 2000);
        })
        .catch((err) => {
            dispatch(
                orderTariffError(pathOr('', ['response', 'status'], err))
            );
            dispatch(makeAlertNotification('Щось пішло не так'));
        }).finally(() => dispatch(setLoading(false)));
};
