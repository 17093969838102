import { createSlice } from '@reduxjs/toolkit';

const activeflipId = createSlice({
    name: 'flip',
    initialState: [],
    reducers: {
        setFilpId(state, {payload}) {
            const newState = state.filter((item) => item.id !== payload)
            return [...newState, {id: payload}]
        },
        removeFlipId(state, {payload}) {
            return state.filter((item) => item.id !== payload);
        },
    }
})

export const {setFilpId, removeFlipId} = activeflipId.actions
export default activeflipId.reducer