import {createSlice} from '@reduxjs/toolkit';

const loading = createSlice({
    name: 'loader',
    initialState:  false,
    reducers: {
        setLoading(state, {payload}) {
            return state = payload
        },
    }
})

export const {setLoading} = loading.actions
export default loading.reducer