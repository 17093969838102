import { Scrollbars } from "rc-scrollbars";
import Loaders from "../../../Loader/Loader";
import styles from "./ConnectElemet.module.css";
import MyInput from "../../../../common/MyInput/MyInput";
import classNames from "classnames";
import MyButton from "../../../../common/MyButton/MyButton";
import SendIcon from "@material-ui/icons/Send";
import ReCAPTCHA from "react-google-recaptcha";
import React from "react";
import * as PropTypes from "prop-types";

export function MobileConnectElement(props) {
  return <Scrollbars autoHeight={true} autoHeightMin={640} autoHide={true}>
    <div>
      {props.load ? (
        <Loaders/>
      ) : (
        <form className={styles.form} onSubmit={props.handlerOnSubmit}>
          <div className={styles.tariff}>
            <MyInput
              disabled
              label={props.tariffTitleCost}
              defaultValue={props.currentTariff.cost}
              size="small"
            />
            <MyInput
              disabled
              label={props.tariffTitleSpeed}
              defaultValue={props.currentTariff.speed}
              size="small"
            />
            <MyInput
              disabled
              label={props.tariffTitlePackage}
              defaultValue={props.currentTariff.name}
              size="small"
            />
          </div>

          <div className={styles.abonent}>
            <MyInput
              type="text"
              value={props.nameRedux}
              onChange={props.handleOnChangeName}
              label={props.formYourName}
              size="medium"
            />
            <p
              className={classNames(
                styles.validation,
                props.validName && styles.valid
              )}
            >
              {props.validName
                ? props.valid
                : props.inValid}
            </p>
            <MyInput
              type="text"
              value={props.phoneRedux}
              onChange={props.handleOnChangePhone}
              label={props.formYourAddress}
              size="medium"
            />
            <p
              className={classNames(
                styles.validation,
                props.validPhone && styles.valid
              )}
            >
              {props.validPhone
                ? props.valid
                : props.inValid}
            </p>
            <MyInput
              type="text"
              value={props.tariffRedux}
              onChange={props.handleOnChangeAddress}
              label={props.formYourPhonec}
              size="medium"
            />
            <p
              className={classNames(
                styles.validation,
                props.validAddress && styles.valid
              )}
            >
              {props.validAddress
                ? props.valid
                : props.inValid}
            </p>
          </div>

          <div className={styles.containerBtn}>
            <MyButton
              type="submit"
              variant="contained"
              color="primary"
              endIcon={<SendIcon/>}
              styles="servicesSended"
            >
              {props.send}
            </MyButton>
            <ReCAPTCHA
              ref={props.ref}
              sitekey={process.env.REACT_APP_GOOGLE_KEY}
              onChange={props.onSetToken}
              onExpired={props.onExpired}
              size={props.mobile ? "compact" : "normal"}
              hl={props.hl}
            />
          </div>
        </form>
      )}
    </div>
  </Scrollbars>;
}

MobileConnectElement.propTypes = {
  load: PropTypes.any,
  handlerOnSubmit: PropTypes.func,
  tariffTitleCost: PropTypes.any,
  currentTariff: PropTypes.any,
  tariffTitleSpeed: PropTypes.any,
  tariffTitlePackage: PropTypes.any,
  nameRedux: PropTypes.any,
  handleOnChangeName: PropTypes.func,
  formYourName: PropTypes.any,
  validName: PropTypes.any,
  valid: PropTypes.any,
  inValid: PropTypes.any,
  phoneRedux: PropTypes.any,
  handleOnChangePhone: PropTypes.func,
  formYourAddress: PropTypes.any,
  validPhone: PropTypes.any,
  tariffRedux: PropTypes.any,
  handleOnChangeAddress: PropTypes.func,
  formYourPhonec: PropTypes.any,
  validAddress: PropTypes.any,
  send: PropTypes.any,
  ref: PropTypes.any,
  onSetToken: PropTypes.func,
  onExpired: PropTypes.func,
  mobile: PropTypes.bool,
  hl: PropTypes.any
};