import {createSlice} from "@reduxjs/toolkit";

const user = createSlice({
    name: 'user',
    initialState: {
        name: '',
        address: '',
        phone: ''
    },
    reducers: {
        setNameAction(state, {payload}) {
             state.name = payload
        },
        setPhoneAction(state, {payload}) {
             state.phone = payload
        },
        setAddressAction(state, {payload}) {
             state.address = payload
        }
    }
})

export const {setNameAction, setPhoneAction, setAddressAction } = user.actions
export default user.reducer