import {NavLink} from "react-router-dom";
import HomeIcon from "@material-ui/icons/Home";
import GavelIcon from "@material-ui/icons/Gavel";
import ButtonCTA from "../common/ButtonCart/ButtonCTA";
import {setModalShop} from "../redux/modal/modalSlice";
import style from "../components/Header/Header.module.css";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import MailIcon from "@material-ui/icons/Mail";
import Public from "@material-ui/icons/Public";
import React from "react";
import {useDispatch} from "react-redux";
import {useTranslation} from "react-i18next";

export const useNavItems = (handleClickMenu) => {
   const dispatch = useDispatch()
    const { t } = useTranslation();

    const listItems = [
        {
            link: (
                <NavLink to={'/home'} exact>
                    {t('nav.main')}
                </NavLink>
            ),
            icon: <HomeIcon />,
        },
        {
            link: (
                <NavLink to={'/services'} exact>
                    {t('nav.services')}
                </NavLink>
            ),
            icon: <GavelIcon />,
        },
        {
            link: (
                <ButtonCTA action={() => dispatch(setModalShop(true))} styles={style.button} children={'Kорзина'}/>
            ),
            icon: <ShoppingCartIcon/>,
        },
        {
            link: (
                <ButtonCTA action={handleClickMenu} styles={style.button} children={t('nav.office')}/>
            ),
            icon: <MailIcon />,
        },
        {
            link: (
              <a className={style.buttonMenu} tabIndex={2} href={'http://fullinet.com/dogovor/Fullinet.pdf'} target="_blank" rel="noopener noreferrer">
                  Договір
              </a>
            ),
            icon: <Public />,
        },
    ];
    return listItems
}