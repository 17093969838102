import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { modalShopSelector } from '../../../redux/modal/modalSelectors';
import { setModalShop } from '../../../redux/modal/modalSlice';
import ShopElementsCore from '../ModalElements/ShopElemets/ShopElementsCore';
import ModalPortal from '../../../common/ModalPortal/ModalPortal';
import Modal from '../Modal';

export default function ModalConnect() {
  const dispatch = useDispatch();
  const isOpen = useSelector(modalShopSelector);
  const closeModal = () => dispatch(setModalShop(false));
  return isOpen ? (
    <ModalPortal>
      <Modal onClose={closeModal} position="centerShop" >
        <ShopElementsCore />
      </Modal>
    </ModalPortal>
  ) : null;
}
