import { createSlice } from '@reduxjs/toolkit';

const responseServices = createSlice({
  name: 'service',
  initialState: null,
  reducers: {
    orderServiceRequest(state){
      return state = null
    },
    orderServiceSuccess(state){
      return state = null
    },
    orderServiceError(state, {payload}){
      return state = payload
    },
  }
})

export const {orderServiceRequest, orderServiceSuccess, orderServiceError} = responseServices.actions
export default responseServices.reducer


