import { createSlice } from '@reduxjs/toolkit';

const responseTariff = createSlice({
  name: 'service',
  initialState: null,
  reducers: {
    orderTariffRequest(state){
      return state = null
    },
    orderTariffSuccess(state){
      return state = null
    },
    orderTariffError(state, {payload}){
      return state = payload
    },
  }
})

export const {orderTariffRequest, orderTariffSuccess, orderTariffError} = responseTariff.actions
export default responseTariff.reducer




