import styles from './ServicesItemShop.module.css';
import Image from '../../../../../common/Image/Image';
import RemoveSVG from '../../../../../common/IconSvg/RemoveSVG';
import React from 'react';
import * as PropTypes from 'prop-types';

export function ServiceItemDesctop(props) {
  return (
    <li className={styles.container}>
      <div className={styles.icon}>
        <Image
          src={props.icon ? props.icon : props.iconGoole}
          alt={props.alt}
          size="shop_size"
        />
      </div>

      <div className={styles.container_desc}>
        <h3>{props.title}</h3>
        <p>{props.descriptions}</p>
        <p className={styles.price}>
          {props.totalCost} <span>{props.detailsPrice}</span>
        </p>
      </div>
      <div className={styles.container_bth}>
        <button className={styles.plus} onClick={props.onIncrement}>
          +
        </button>
        <p className={styles.qty}>{`${props.qty}`}</p>
        <button className={styles.minus} onClick={props.onDecrement}>
          -
        </button>
        <RemoveSVG onClick={props.onRemoveFromCart} />
      </div>
    </li>
  );
}

ServiceItemDesctop.propTypes = {
  icon: PropTypes.any,
  iconGoogle: PropTypes.any,
  alt: PropTypes.any,
  title: PropTypes.any,
  descriptions: PropTypes.any,
  totalCost: PropTypes.number,
  detailsPrice: PropTypes.any,
  onIncrement: PropTypes.func,
  qty: PropTypes.any,
  onDecrement: PropTypes.func,
  onRemoveFromCart: PropTypes.func,
};
