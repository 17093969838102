import { lazy } from 'react';

export default [
  {
    path: '/home',
    label: 'home',
    component: lazy(() =>
      import('../views/HomeView/HomeView' /*webpackChunkName: "home" */)
    ),
  },
  {
    path: '/services',
    label: 'services',
    component: lazy(() =>
      import(
        '../views/ServicesView/ServicesView.js' /*webpackChunkName: "services" */
      )
    ),
  },
];
