import { createSlice } from '@reduxjs/toolkit';

const cart = createSlice({
    name: 'shop',
    initialState: {cart: []},
    reducers: {
        addToCart(state, {payload: service}) {
            const inCart = state.cart.find((itemInCart) => itemInCart._id === service._id)
            const newState = state.cart.map(item => item._id === service._id ? {...item, qty: item.qty + 1} : item)
            if (inCart) {
                state.cart = [...newState]
            }
            if (!inCart) {
                service.qty = 1
                state.cart.push(service)
            }
        },
        removeFromCart(state, {payload: serviceId}) {
            return {cart: state.cart.filter((item) => item._id !== serviceId)}
        },
        increment(state, {payload: serviceId}) {
            return {
                cart: state.cart.map((item) =>
                    item._id === serviceId ? {...item, qty: item.qty + 1} : item
                )
            }
        },
        decrement(state, {payload: serviceId}) {
            return {
                cart: state.cart.map((item) => {
                    return item._id === serviceId ? {...item, qty: item.qty <= 1 ? (item.qty = 1) : item.qty - 1} : item
                })
            }
        }
    }
})
export const {addToCart, removeFromCart, increment, decrement} = cart.actions
export default cart.reducer