export const dataServicesToSend = (
  nameRedux,
  phoneRedux,
  tariffRedux,
  totalPriceOnePay,
  totalPriceAtMonth,
  totalItemsAtMonth,
  totalItemsOnePay,
  totalItems,
  cart
) => {
  const credentials = {
    name: nameRedux,
    phone: phoneRedux,
    address: tariffRedux,
  };
  const servicesToSend = cart.map((item) => {
    return {
      alt: item.alt,
      qty: item.qty,
      price: item.price,
      details_price: item.detailsPrice,
    };
  });
  const totalInfoToSend = {
    totalPriceOnePay: totalPriceOnePay,
    totalPriceAtMonth: totalPriceAtMonth,
    totalItemsAtMonth: totalItemsAtMonth,
    totalItemsOnePay: totalItemsOnePay,
    totalItems: totalItems,
  };
  return {credentials, servicesToSend, totalInfoToSend}
}

export const calculateServicesPriceAndTotal = (
  setTotalPriceAtMonth,
  setTotalItemsAtMonth,
  setTotalPriceOnePay,
  setTotalItemsOnePay,
  setTotalItems,
  totalItemsAtMonth,
  totalItemsOnePay,
  cart
  ) =>
{
  let itemsOnePay = 0;
  let priceOnePay = 0;

  let itemsAtMonth = 0;
  let priceAtMonth = 0;
  cart.forEach((item) => {
    if (item.onMonth === 'true') {
      itemsAtMonth += item.qty;
      priceAtMonth += item.qty * item.price;

      setTotalPriceAtMonth(priceAtMonth);
      setTotalItemsAtMonth(itemsAtMonth);
    }
    if (item.onMonth === 'false') {
      itemsOnePay += item.qty;
      priceOnePay += item.qty * item.price;

      setTotalPriceOnePay(priceOnePay);
      setTotalItemsOnePay(itemsOnePay);
    }
    setTotalItems(totalItemsAtMonth + totalItemsOnePay);
  });
}