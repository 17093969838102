import React from 'react';

import style from './IconSVG.module.css';

export default function IconSvg({ onClick }) {
  return (
    <div className={style.RemoveSVG_container}>
      <svg 
        onClick={onClick}
        width="30"
        height="30"
        viewBox="0 0 20 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10 0.5C4.47715 0.5 0 4.97715 0 10.5C0 16.0228 4.47715 20.5 10 20.5C15.5228 20.5 20 16.0228 20 10.5C20 4.97715 15.5228 0.5 10 0.5ZM11.0064 5.22437H9.00086V5.56183H8.2765V5.17712C8.2765 4.80377 8.58009 4.5 8.95326 4.5H11.054C11.4272 4.5 11.7308 4.80377 11.7307 5.17712V5.56183H11.0064V5.22437ZM13.4034 8.43164H6.60393C6.41762 8.43164 6.27095 8.59058 6.28597 8.77634L6.85442 15.8054C6.88609 16.1978 7.2134 16.5 7.60661 16.5H12.4006C12.7938 16.5 13.1211 16.1978 13.1528 15.8053L13.7212 8.77634C13.7363 8.59058 13.5897 8.43164 13.4034 8.43164ZM8.25032 15.7503C8.24272 15.7507 8.23512 15.751 8.22762 15.751C8.03774 15.751 7.87834 15.6031 7.86653 15.4111L7.5103 9.64059C7.49803 9.44092 7.64992 9.26907 7.8495 9.25681C8.04845 9.24472 8.22102 9.39624 8.23329 9.59601L8.58943 15.3665C8.60179 15.5662 8.44991 15.7379 8.25032 15.7503ZM10.3699 15.3888C10.3699 15.5888 10.2077 15.7509 10.0077 15.7509C9.80763 15.7509 9.64549 15.5888 9.64549 15.3888V9.61826C9.64549 9.41821 9.80763 9.25607 10.0077 9.25607C10.2076 9.25607 10.3699 9.41821 10.3699 9.61826V15.3888ZM12.497 9.63959L12.1569 15.4101C12.1456 15.6025 11.9859 15.7509 11.7957 15.7509L11.7809 15.7507L11.7741 15.7504C11.5744 15.7386 11.4221 15.5672 11.4339 15.3675L11.7739 9.59692C11.7856 9.39725 11.9565 9.2449 12.1567 9.25671C12.3564 9.26843 12.5087 9.43991 12.497 9.63959ZM14.418 6.60096L14.6559 7.31397C14.7017 7.45139 14.642 7.59165 14.5306 7.66159C14.4853 7.69016 14.4315 7.70728 14.3724 7.70728H5.63498C5.57593 7.70728 5.52218 7.69016 5.47677 7.66169C5.36535 7.59174 5.30566 7.45148 5.35153 7.31397L5.58938 6.60096C5.65201 6.413 5.82806 6.2862 6.02618 6.2862H13.9811C14.1793 6.2862 14.3553 6.413 14.418 6.60096Z"
          fill="#A6A6A6"
        />
      </svg>
    </div>
  );
}
