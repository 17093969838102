import { Scrollbars } from "rc-scrollbars";
import classNames from "classnames";
import styles from "./ShopElemets.module.css";
import Loaders from "../../../Loader/Loader";
import MyInput from "../../../../common/MyInput/MyInput";
import ReCAPTCHA from "react-google-recaptcha";
import Button from "@material-ui/core/Button";
import SendIcon from "@material-ui/icons/Send";
import MyButton from "../../../../common/MyButton/MyButton";
import React from "react";
import * as PropTypes from "prop-types";

export function MobileShopElement(props) {
  return <Scrollbars
    autoHeight={true}
    autoHeightMin={props.mobile ? 773 : 675}
    autoHide={true}
  >
    <section
      className={classNames(
        styles.sectionForm,
        props.load && styles.loadingStyle
      )}
      style={
        props.mobile && props.flipId?.id === props.idButtonForStyles.current
          ? props.styleWhenFliped.current
          : {}
      }
    >
      {props.load ? (
        <Loaders position={"none"}/>
      ) : (
        <form className={styles.form} onSubmit={props.onSubmit}>
          <div className={styles.abonent}>
            <MyInput
              type="text"
              value={props.nameRedux}
              onChange={props.handleOnChangeName}
              label={props.formYourName}
              size="small"
            />
            <p
              className={classNames(
                styles.validation,
                props.validName && styles.valid
              )}
            >
              {props.validName
                ? props.valid
                : props.invalid}
            </p>
            <MyInput
              type="text"
              value={props.phoneRedux}
              onChange={props.handleOnChangePhone}
              label={props.formYourPhone}
              size="small"
            />
            <p
              className={classNames(
                styles.validation,
                props.validPhone && styles.valid
              )}
            >
              {props.validPhone
                ? props.valid
                : props.invalid}
            </p>
            <MyInput
              type="text"
              value={props.tariffRedux}
              onChange={props.handleOnChangeAddress}
              label={props.formYourAddress}
              size="small"
            />
            <p
              className={classNames(
                styles.validation,
                props.validAddress && styles.valid
              )}
            >
              {props.validAddress
                ? props.valid
                : props.invalid}
            </p>
          </div>

          <div className={styles.containerBtn}>
            <ReCAPTCHA
              ref={props.ref}
              sitekey={process.env.REACT_APP_GOOGLE_KEY}
              onChange={props.onSetToken}
              onExpired={props.onExpired}
              size={props.mobile ? "compact" : "normal"}
              hl={props.hl}
            />
            <Button
              variant="contained"
              color="primary"
              type="submit"
              endIcon={<SendIcon/>}
            >
              {props.send}
            </Button>
          </div>
        </form>
      )}

      {!props.load && (
        <div className={styles.price_container_fliped}>
          <p className={styles.price}>
            {props.month_pay_price}
            <span>{props.totalPriceAtMonth}грн.</span>
          </p>
          <p className={styles.price}>
            {props.one_pay_price}
            <span>{props.totalPriceOnePay}грн.</span>
          </p>
          <p className={styles.price}>
            {props.total_services} <span>{props.totalItems}</span>
          </p>
        </div>
      )}
      {!props.load && (
        <div className={styles.container_buttonForm}>
          <MyButton
            styles="services"
            id={props.idButtonForStyles.current}
            onClick={props.handleOnClickPrev}
          >
            <span id={props.idButtonForStyles.current}>{props.back}</span>
          </MyButton>
        </div>
      )}
    </section>
  </Scrollbars>;
}
MobileShopElement.propTypes = {
  mobile: PropTypes.bool,
  load: PropTypes.any,
  flipId: PropTypes.any,
  idButtonForStyles: PropTypes.any,
  styleWhenFliped: PropTypes.any,
  onSubmit: PropTypes.func,
  nameRedux: PropTypes.any,
  handleOnChangeName: PropTypes.func,
  formYourName: PropTypes.any,
  validName: PropTypes.any,
  valid: PropTypes.any,
  invalid: PropTypes.any,
  phoneRedux: PropTypes.any,
  handleOnChangePhone: PropTypes.func,
  formYourPhone: PropTypes.any,
  validPhone: PropTypes.any,
  tariffRedux: PropTypes.any,
  handleOnChangeAddress: PropTypes.func,
  formYourAddress: PropTypes.any,
  validAddress: PropTypes.any,
  ref: PropTypes.any,
  onSetToken: PropTypes.func,
  onExpired: PropTypes.func,
  hl: PropTypes.any,
  send: PropTypes.any,
  month_pay_price: PropTypes.any,
  totalPriceAtMonth: PropTypes.number,
  one_pay_price: PropTypes.any,
  totalPriceOnePay: PropTypes.number,
  total_services: PropTypes.any,
  totalItems: PropTypes.number,
  handleOnClickPrev: PropTypes.func,
  back: PropTypes.any
};