import { createSlice } from '@reduxjs/toolkit';

const modal = createSlice({
  name: 'modal',
  initialState: {
    connect: null,
    shop: null
  },
  reducers: {
    setModalConnect(state, {payload}){
       state.connect = payload
    },
    setModalShop(state, {payload}){
       state.shop = payload
    }
  }
})

export const {setModalConnect, setModalShop} = modal.actions
export default modal.reducer