import { DevSupport } from '@react-buddy/ide-toolbox';
import { ComponentPreviews, useInitial } from './dev';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { HashRouter } from 'react-router-dom';

import { store, persistor } from './redux/store';
import './styles/index.css';
import App from './components/App';

ReactDOM.render(
  <Provider store={store} persistor={persistor}>
    <HashRouter>
      <DevSupport
        ComponentPreviews={ComponentPreviews}
        useInitialHook={useInitial}
      >
        <App />
      </DevSupport>
    </HashRouter>
  </Provider>,
  document.querySelector('#root')
);
