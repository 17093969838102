import styles from "./ShopElemets.module.css";
import MyButton from "../../../../common/MyButton/MyButton";
import React from "react";
import * as PropTypes from "prop-types";

export function ShopInterface(props) {
  return <section
    style={
      props.isMobile && props.flipId && props.flipId.id === props.idButtonForStyles.current
        ? props.styleWhenFlipedFront.current
        : {}
    }
  >
    <h2 className={styles.title}>Ваша корзина</h2>

    <div className={styles.price_container}>
      <p className={styles.price}>
        {props.month_pay_price}
        <span>{props.totalPriceAtMonth}грн.</span>
      </p>
      <p className={styles.price}>
        {props.one_pay_price}
        <span>{props.totalPriceOnePay}грн.</span>
      </p>
      <p className={styles.price}>
        {props.total_services} <span>{props.totalItems}</span>
      </p>
      <div className={styles.container_details_btn}>
        <MyButton
          styles="services"
          id={props.idButtonForStyles.current}
          onClick={props.onClick}
        >
          <span id={props.idButtonForStyles.current}>{props.order}</span>
        </MyButton>
      </div>
    </div>

    <div className={styles.container}>
      <div>
        {props.cart.length <= 0 && (
          <p className={styles.cart_empty}>{props.cart_empty}</p>
        )}
        {props.cart.length > 0 &&
        props.cart.map(props.servicesItemShop)}
      </div>
    </div>
  </section>;
}

ShopInterface.propTypes = {
  isMobile: PropTypes.bool,
  flipId: PropTypes.any,
  idButtonForStyles: PropTypes.any,
  styleWhenFlipedFront: PropTypes.any,
  month_pay_price: PropTypes.any,
  totalPriceAtMonth: PropTypes.number,
  one_pay_price: PropTypes.any,
  totalPriceOnePay: PropTypes.number,
  total_services: PropTypes.any,
  totalItems: PropTypes.number,
  onClick: PropTypes.func,
  order: PropTypes.any,
  cart: PropTypes.any,
  cart_empty: PropTypes.any,
  servicesItemShop: PropTypes.func
};