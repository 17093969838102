import {pathOr} from 'ramda';

import {orderServiceRequest, orderServiceSuccess, orderServiceError} from './servicesSlice';
import {setModalShop} from '../modal/modalSlice';
import {sendServicesWithCredentials} from '../../utils/fullinetAPI';
import {
    makeSuccessSendedNotification,
    makeSuccessUsersInfoNotification,
    makeAlertNotification } from '../notifications/notificationOperations';
import {setLoading} from "../loader/loaderSlice";

export const sendServices = (
    sendServices,
    credentials,
    totalInfoToSend,
    token
) => (dispatch) => {
    dispatch(setLoading(true))
    dispatch(orderServiceRequest);
    sendServicesWithCredentials(sendServices, credentials, totalInfoToSend, token)
        .then((response) => {
            dispatch(orderServiceSuccess(response.status));
            dispatch(makeSuccessSendedNotification('Успішно відправлено'));
            setTimeout(() => {
                dispatch(
                    makeSuccessUsersInfoNotification(
                        'Ваше замовлення вже в потрібних руках. Натисніть щоб закрити.'
                    )
                );
            }, 2000);

            setTimeout(() => {
                dispatch(setModalShop(false));
            }, 2000);
        })
        .catch((err) => {
            dispatch(orderServiceError(pathOr('', ['response', 'status'], err)));
            dispatch(makeAlertNotification('Щось пішло не так'));
        }).finally(() => dispatch(setLoading(false)));
};
