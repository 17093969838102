import { setNotification, unsetNotification } from './notificationSlice';

export const makeSuccessNotification = (text) => (dispatch) => {
  const params = {
    type: 'success',
    message: text,
  };
  dispatch(setNotification(params));
  setTimeout(() => dispatch(unsetNotification(params)), 4000);
};
export const makeAlertNotification = (text) => (dispatch) => {
  const params = {
    type: 'alert',
    message: text,
  };
  dispatch(setNotification(params));
  setTimeout(() => dispatch(unsetNotification(params)), 4000);
};
export const makeSuccessSendedNotification = (text) => (dispatch) => {
  const params = {
    type: 'successSended',
    message: text,
  };
  dispatch(setNotification(params));
  setTimeout(() => dispatch(unsetNotification(params)), 3500);
};
export const makeSuccessUsersInfoNotification = (text) => (dispatch) => {
  const params = {
    type: 'userInfo',
    message: text,
  };
  dispatch(setNotification(params));
  setTimeout(() => {
    dispatch(unsetNotification(params));
  }, 30000);
};
