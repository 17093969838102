import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactCardFlip from 'react-card-flip';

import useToggle from '../../../../hooks/useToggle';
import useTitle from '../../../../hooks/useTitle';
import useIsMobile from '../../../../hooks/useMobile';
import { useTranslation } from 'react-i18next';
import '../../../../utils/i18next';
import ServicesItemShop from './ShopItems/ServicesItemShop';
import { cartSelector } from '../../../../redux/shop/shopSelectors';
import { idFlipSelector } from '../../../../redux/fliped/flipedSelector';
import { getAddressSelector, getNameSelector, getPhoneSelector } from '../../../../redux/user/userSelectors';
import { isAnyModalOpenSelector } from '../../../../redux/modal/modalSelectors';
import { setAddressAction, setNameAction, setPhoneAction } from '../../../../redux/user/userSlice';
import { makeAlertNotification } from '../../../../redux/notifications/notificationOperations';
import { removeFlipId, setFilpId } from '../../../../redux/fliped/flipedSlice';
import { sendServices } from '../../../../redux/services/servicesOperations';
import { loadingSelector } from '../../../../redux/loader/loaderSelector';
import useValiadField from '../../../../hooks/useValidateField';
import { DesctopShopElement } from "./DesctopShopElement";
import { MobileShopElement } from "./MobileShopElement";
import { ShopInterface } from "./ShopInterface";
import { calculateServicesPriceAndTotal, dataServicesToSend } from "../../../../utils/helpFunctions";

export default function ShopElementsCore() {
  const [totalPriceOnePay, setTotalPriceOnePay] = useState(0);
  const [totalPriceAtMonth, setTotalPriceAtMonth] = useState(0);
  const [totalItemsAtMonth, setTotalItemsAtMonth] = useState(0);
  const [totalItemsOnePay, setTotalItemsOnePay] = useState(0);
  const [totalItems, setTotalItems] = useState(0);

  const [token, setToken] = useState('');

  const [validName, setValidName] = useValiadField();
  const [validPhone, setValidPhone] = useValiadField();
  const [validAddress, setValidAddress] = useValiadField();

  const [isFlip, onFlip] = useToggle();

  const nameRedux = useSelector(getNameSelector);
  const phoneRedux = useSelector(getPhoneSelector);
  const tariffRedux = useSelector(getAddressSelector);
  const isAnyModalOpen = useSelector(isAnyModalOpenSelector);
  const load = useSelector(loadingSelector);
  const idButtonForStyles = useRef('buttonNextShop');
  const flipId = useSelector(idFlipSelector(idButtonForStyles.current));

  const cart = useSelector(cartSelector);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  useTitle('Корзина || Fullinet');
  const isMobile = useIsMobile();
  const recaptchaRef = React.useRef();

  useEffect(() => {
    return () => {
      if (!isAnyModalOpen) {
        dispatch(removeFlipId(idButtonForStyles.current));
      }
    };
  }, [dispatch, isAnyModalOpen]);
  useEffect(() => {
    setValidName(nameRedux);
    setValidPhone(phoneRedux);
    setValidAddress(tariffRedux);
  }, [nameRedux, phoneRedux, tariffRedux]);

  useEffect(() => {
    calculateServicesPriceAndTotal(
      setTotalPriceAtMonth,
      setTotalItemsAtMonth,
      setTotalPriceOnePay,
      setTotalItemsOnePay,
      setTotalItems,
      totalItemsAtMonth,
      totalItemsOnePay,
      cart
    );
  }, [
    cart,
    totalPriceOnePay,
    totalPriceAtMonth,
    totalItemsAtMonth,
    totalItemsOnePay,
  ]);

  const handlerOnSubmit = (e) => {
    e.preventDefault();
    if (!token) {
      dispatch(makeAlertNotification(t('validation.tokenAlert')));
      return;
    }
    if (!validName) {
      dispatch(makeAlertNotification(t('validation.alertIdName')));
      return;
    }
    if (!validPhone) {
      dispatch(makeAlertNotification(t('validation.alertIdPhone')));
    }
    if (!validAddress) {
      dispatch(makeAlertNotification(t('validation.alertIdAddress')));
    }

   const dataToSend = dataServicesToSend(
      nameRedux,
      phoneRedux,
      tariffRedux,
      totalPriceOnePay,
      totalPriceAtMonth,
      totalItemsAtMonth,
      totalItemsOnePay,
      totalItems,
      cart
    )
    console.log( dataToSend.servicesToSend)
    if (token) {
      dispatch(sendServices(
        dataToSend.servicesToSend,
        dataToSend.credentials,
        dataToSend.totalInfoToSend,
        token));
      return;
    }
  };

  const handleOnChangeName = ({ target: { value } }) => {
    dispatch(setNameAction(value));
    setValidName(value);
  };
  const handleOnChangePhone = ({ target: { value } }) => {
    dispatch(setPhoneAction(value));
    setValidPhone(value);
  };
  const handleOnChangeAddress = ({ target: { value } }) => {
    dispatch(setAddressAction(value));
    setValidAddress(value);
  };
  const handleOnClickNext = (e) => {
    onFlip();
    dispatch(setFilpId(e.target.id));
  };
  const handleOnClickPrev = (e) => {
    onFlip();
    dispatch(removeFlipId(e.target.id));
  };

  const styleWhenFliped = useRef({
    display: 'grid',
  });
  const styleWhenFlipedFront = useRef({
    display: 'none',
  });
  return (
    <ReactCardFlip
      isFlipped={flipId && flipId.id === idButtonForStyles.current}
      flipDirection="horizontal"
    >
      <ShopInterface
        isMobile={isMobile}
        flipId={flipId}
        idButtonForStyles={idButtonForStyles}
        styleWhenFlipedFront={styleWhenFlipedFront}
        cart={cart}

        servicesItemShop={(item) => <ServicesItemShop key={item._id} dataItem={item}/>}
        onClick={(e) => handleOnClickNext(e)}

        totalPriceAtMonth={totalPriceAtMonth}
        totalPriceOnePay={totalPriceOnePay}
        totalItems={totalItems}

        order={t('ui.order')}
        total_services={t('shop.total_services')}
        month_pay_price={t('shop.month_pay_price')}
        one_pay_price={t('shop.one_pay_price')}
        cart_empty={t('shop.cart_empty')}
      />

      {!isMobile ? (
        <DesctopShopElement
          load={load}
          mobile={isMobile}
          flipId={flipId}
          ref={recaptchaRef}
          idButtonForStyles={idButtonForStyles}
          styleWhenFliped={styleWhenFliped}

          onSubmit={handlerOnSubmit}
          handleOnClickPrev={handleOnClickPrev}
          handleOnChangeName={handleOnChangeName}
          handleOnChangePhone={handleOnChangePhone}
          handleOnChangeAddress={handleOnChangeAddress}
          onSetToken={(token) => setToken(token)}
          onExpired={() => setToken('')}

          phoneRedux={phoneRedux}
          validPhone={validPhone}
          tariffRedux={tariffRedux}
          nameRedux={nameRedux}
          validName={validName}
          validAddress={validAddress}
          totalPriceAtMonth={totalPriceAtMonth}
          totalPriceOnePay={totalPriceOnePay}
          totalItems={totalItems}

          formYourName={t('form.yourName')}
          formYourPhone={t('form.yourPhone')}
          formYourAddress={t('form.yourAddress')}
          month_pay_price={t('shop.month_pay_price')}
          one_pay_price={t('shop.one_pay_price')}
          valid={t('validation.valid')}
          invalid={t('validation.invalidField')}
          total_services={t('shop.total_services')}
          back={t('ui.back')}
          send={t('ui.send')}
          hl={t('recapchaHL')}/>
      ) : (
        <MobileShopElement
          mobile={isMobile}
          load={load}
          flipId={flipId}
          idButtonForStyles={idButtonForStyles}
          ref={recaptchaRef}
          styleWhenFliped={styleWhenFliped}

          validPhone={validPhone}
          validName={validName}
          nameRedux={nameRedux}
          phoneRedux={phoneRedux}
          tariffRedux={tariffRedux}
          validAddress={validAddress}
          totalPriceAtMonth={totalPriceAtMonth}
          totalPriceOnePay={totalPriceOnePay}
          totalItems={totalItems}

          onSubmit={handlerOnSubmit}
          handleOnChangeName={handleOnChangeName}
          handleOnClickPrev={handleOnClickPrev}
          handleOnChangePhone={handleOnChangePhone}
          handleOnChangeAddress={handleOnChangeAddress}
          onSetToken={(token) => setToken(token)}
          onExpired={() => setToken('')}

          formYourName={t('form.yourName')}
          formYourPhone={t('form.yourPhone')}
          formYourAddress={t('form.yourAddress')}
          hl={t('recapchaHL')}
          valid={t('validation.valid')}
          invalid={t('validation.invalidField')}
          send={t('ui.send')}
          month_pay_price={t('shop.month_pay_price')}
          one_pay_price={t('shop.one_pay_price')}
          total_services={t('shop.total_services')}
          back={t('ui.back')}/>
      )}
    </ReactCardFlip>
  );
}