import React from 'react';

const ButtonCTA = ({action, styles, children}) => {
    return (
        <button
            className={styles}
            onClick={action}
        >
            {children}
        </button>
    );
};

export default ButtonCTA;